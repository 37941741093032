// Styles for tk-approvablesheet directive

.tk-approvablesheet {
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
  margin: 0px 0px 20px 0px;
  padding: 28px;
  @include tk-box-shadow(0px, 1px, 3px, 0px, rgba(0, 0, 0, 0.2));
}

.tk-approvablesheet--submitted {
  @include tk-transition-all;
  @include tk-box-shadow(0px, 0px, 0px, 1px, #d1d1d1, true);
  background-color: #f2f2f2;
  border-radius: 4px;
}

.tk-approvablesheet__message {
  text-align: center;
}

.tk-approvablesheet__message--two-lines {
  margin-top: -7px;
}

// .tk-approvablesheet__content {}

.tk-approvablesheet__controls {
  @include tk-clearfix();
  margin-top: 10px;
  button {
    float:left;
    margin: 0px;
    &:last-child {
      float: right;
    }
  }
}

.tk-approvablesheet__controls--expense {
  margin-top: 30px;
}
