/* `Multi-pane
 * Make sure to set width and height of .tk-multi-pane for your instance.
--------------------------------------------------------------------------------*/

.tk-multi-pane {
  $paneTitleHeight: 50px;

  .panes {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;  
  }

  .pane {
    position: absolute;
    top: 0;
    left: 0;

    &.hidden {
      display: none;
    }
  }

  .pane-link {
    cursor: pointer;
    padding: 5px;
  }
  
  .title-bar {
    height: $paneTitleHeight;
    background-color: #333333;
    color: #fff;
    padding: 15px 18px 18px 18px;
    position: relative;

    /*h3*/.pane-title {
      font-family: 'GibsonSemiBold', sans-serif;
      font-size: 15px;
      text-align: center;
      font-weight: normal;
    }

    .back-button {
      position: absolute;
      font-size: 15px;
      cursor: pointer;
    }
  }

}