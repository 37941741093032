// mixins
// bootstrap splits these into separate files

// @import "variables.css.scss";

@mixin tk-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin tk-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin tk-h3 {
  font-family: $tk-font-light;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}

@mixin tk-box-shadow($top: 0, $left: 0, $blur: 8px, $spread: 0px, $color: rgba(0,0,0,0.3), $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $spread $color;
    -moz-box-shadow:inset $top $left $blur $spread $color;
    box-shadow:inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

@mixin tk-inset-box-shadow($top: 0, $left: 0, $blur: 8px, $spread: 0px, $color: rgba(0,0,0,0.3), $inset: true) {
  @include tk-box-shadow($top, $left, $blur, $spread, $color, $inset)
}

@mixin tk-transform($transformation) {
  -webkit-transform: $transformation;
  transform: $transformation;
}

@mixin tk-transition-all($duration: $tk-default-tranisition-duration-ms) {
  -webkit-transition: all #{$duration} ease;
  -moz-transition: all #{$duration} ease;
  -o-transition: all #{$duration} ease;
  transition: all #{$duration} ease;
}

@mixin tk-transition-inherit {
  -moz-transition: inherit;
  -webkit-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

@mixin tk-transition-delay($duration: $tk-default-tranisition-duration-ms) {
  -webkit-transition-delay: $duration;
  -moz-transition-delay: $duration;
  -o-transition-delay: $duration;
  transition-delay: $duration;
}

@mixin tk-text-shadow-dark {
  text-shadow: -1px 0px 2px rgba(150, 150, 150, 1);
}

@mixin tk-container-with-padding {
  padding: 10px;
}

@mixin tk-clickable {
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
}

@mixin tk-flex {
  display: -webkit-flex;
  display: flex;
}

@mixin tk-flex-justify-space-between {
  justify-content: space-between;
}

@mixin tk-align-self-right {
  -webkit-align-self: -webkit-flex-end;
  align-self: flex-end;
}

@mixin tk-gradient-background($colorStart: rgb(235, 235, 235), $colorStop: rgb(255, 255, 255)) {
  background: -webkit-linear-gradient($colorStart, $colorStop);
  background: -moz-linear-gradient($colorStart, $colorStop);
  background: -o-linear-gradient($colorStart, $colorStop);
  background: linear-gradient($colorStart, $colorStop);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='ie_hex_str($colorStart)', endColorstr='ie_hex_str($colorStop)')";
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$colorStart}', endColorstr='#{$colorStop}', GradientType=0 ); /* IE6-9 */
}

@mixin tk-container-with-no-padding {
  padding: 0px;
}

@mixin tk-debug {
  background-color: rgb(217, 249, 252);
  border: 1px dotted red;
}

@mixin border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// make sure to set a width for this to work properly.
@mixin truncate-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin keyframes( $animationName )
{
  @-webkit-keyframes $animationName {
    @content;
  }
  @-moz-keyframes $animationName {
    @content;
  }
  @-o-keyframes $animationName {
    @content;
  }
  @keyframes $animationName {
    @content;
  }
}

@mixin tk-popover-background($background-color: rgb(255, 255, 255), $border-color: rgb(255, 255, 255)) {
  .tk-popover.bottom > .tk-arrow, .tk-popover.top > .tk-arrow{
    box-shadow: 0px 1px $background-color;
  }

  .tk-popover.right > .tk-arrow, .tk-popover.left > .tk-arrow{
    box-shadow: 1px 0px $background-color;
  }

  .tk-arrow::after {
    background: $background-color;
    border: 1px solid $border-color;
  }
}

@mixin tk-triangle($width, $height, $direction: 0deg, $color: #000, $shadow: false) {
  width: $width;
  height: $height;
  position: absolute;
  overflow: hidden;
  @include tk-transform(rotate($direction - (45deg + 90deg)));
  &:after {
    content: "";
    position: absolute;
    width: $height;
    height: $height;
    background-color: $color;
    top: $height * 0.5;
    left: $height * 0.5;
    @if $shadow {
      @include tk-box-shadow;
    }
    @include tk-transform(rotate(45deg));
  }
}
