.tk-user-badge {
  @include tk-clearfix();
}

.tk-user-badge__avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin-right: 10px;
  float: left;
  img {
    width: 100%;
    max-width: 100%;
  }
}

.tk-user-badge__info {
  line-height: normal;
  float: left;
  height: 40px;
}

.tk-user-badge__info-wrap {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.tk-user-badge__name {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.tk-user-badge__discipline {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
