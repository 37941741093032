// @import "variables.css.scss";
// @import "approvals-bridge-body-tag.css.scss";
// @import "billing-body-tag.css.scss";
// ask andre about two imports for same ref

* {
  box-sizing: border-box;
  &:before, &:after {
    box-sizing: border-box;
  }
}

// These body styles will be ignored by embedded widgets
body {
  background: $tk-background-color;
}

// These body styles will also work with widgets embedded in external apps.
// In that case the styles will be applied to the embedded widget's
// container instead of the body
#{$body-tag} {
  font-family: $tk-font-normal;
  font-size: 14px; // TODO move to variable
  font-weight: normal;
  color: $tk-default-text-color;
  margin: 0px;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  // line-height: inherit; // not sure what this might break by commenting this out
}

// clickable elements
[ng-click] {
  cursor: pointer;
  &.tk-not-clickable {
    cursor: default;
  }
}

