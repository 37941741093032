// @import "mixins.css.scss";
// @import "sprites.css.scss";

button {
  color: #666;
  height: 30px;
  width: auto;
  border-radius: 2px;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px; // TODO consider pushing lower into other styles
  margin-left: 5px;
  margin-right: 5px;
  line-height: 26px;
  font-size: 14px;
  background-color: #c1c1c1;

  &.tk-button-left-most {
    margin-left: 0px;
  }

  &.tk-button-right-most {
    margin-right: 0px;
  }

  &:hover, &.tk-hover {
    background-color: #CCCCCC;
  }

  &:active, &.tk-active {
    background-color: #B2B2B2;
  }

  &:focus {
    outline: none;
  }

  &.tk-button-primary {
    color: #FFFFFF;
    background-color: #3576BA;
    &:hover {
      background-color: #6097D2;
    }
    &:active {
      background-color: #4C649D;
    }
    &:focus {
      background-color: #3576BA;
    }
    &:disabled {
      background-color: #B3CCEA;
    }
  }

  &.tk-button-gray {
    color: rgb(255,255,255);
    @extend .grad-gray;
  }

  &.tk-button-secondary {
    color: #444444;
    background-color: #EEEEEE;
    &:hover {
       background-color: #E0E0E0;
    }
    &:active {
      background-color: #C0C0C0;
    }
    &:focus {
      background-color: #C0C0C0;
    }
    &:disabled {
      color: #979797;
      background-color: #F2F2F2;
    }
  }

  &.tk-button-destructive {
    color: #FFFFFF;
    background-color: #CC2E3B;
    &:hover {
      background-color: #DB656F;
    }
    &:active {
     background-color: #A62833;
    }
    &:focus {
      background-color: #A62833;
    }
    &:disabled {
      background-color: #EDBEC2;
    }
  }

  &.tk-button-blue {
    color: rgb(255,255,255);
    @extend .grad-blue;
    border: 1px solid #75CDFE;
    border-color: #75CDFE;
    &:hover {
      @include tk-gradient-background(#1bcdfe, #0197d2);
    }
    &.active {
      @include tk-gradient-background(#1bcdfe, #0197d2);
    }
  }
}