// @import "mixins.css.scss";

// TODO refactor this code smell out. Needed for other styles to be accurate currently.

div {
  margin: 0;
}

.tk-approval-page-wrapper {
  background-color: #f2f2f2;
  width: 1020px;
  margin: 0px auto;
}

.tk-approval-page__header {
  @include tk-clearfix();
}

.tk-approval-page__title {
  float: left;
  font-family: $tk-font-bold;
  font-size: 32px;
  margin-top: 23px;
}

.tk-approval-page__save-filter {
  float: right;
  button {
    margin: 25px 0px 0px 0px;
  }
}

.tk-approval-page-popover {
  @include tk-clearfix();
  width: 210px;
  padding: 15px;
  input {
    width: 100%;
    height: 30px;
    border-radius: 2px;
  }
  button {
    float:left;
    margin-top: 15px;
    &:last-child {
      margin-left: 10px;
    }
  }
}

.tk-approval-page__content {
  @include tk-clearfix();
  margin-top: 35px;
}

// tk-filter-sidebar already has some styles, leaning on those
// .tk-approval-page__sidebar {}

.tk-approval-page__approvalsheets {
  width: 800px;
  float: right;
}
