/*
* Styles for the approvables tracker widget
*/

$greyIcon: #666;
$timeTrackerRowHeight: 30px;
$timeTrackerRowPadding: 10px;

$timeTrackerRowContainerHeight: $timeTrackerRowHeight + $timeTrackerRowPadding + $timeTrackerRowPadding;


/* `Approvables tracker
--------------------------------------------------------------------------------*/

.tk-approvables-tracker {

  .tk-approvables-empty {
    text-align: center;
    border: solid 1px #e0e0e0;
    padding: 15px;
    border-radius: 3px;
  }

  .tk-approvables-date-control {
    padding: 10px 0px;
    float: right;

    .tk-current-date {
      float: right;
      margin: 0px 0px;
      width: 180px;
      min-height: 28px;
      @include tk-popover-background(#f2f2f2);

      .tk-icon-arrow-left, .tk-icon-arrow-right {
        cursor: pointer;
        margin: 0px 0px 0px 8px;
        font-size: 16px;
        float: left;
        padding: 0px;
        color: inherit;
        background: none;
        line-height: initial;
      }

      .tk-current-date-label {
        font-size: 12px;
        float: left;
        width: 132px;
      }
    }

    .tk-week-day-view-container {
      font-size: 12px;
      float: left;
      margin-right: 30px;

      button {
        background: none;
        padding: 0px;
        margin: 0px;
        display: inline-block;
        color: inherit;
        font-size: 12px;
        line-height: initial;
      }

      .tk-opt-custom, .tk-opt-week, .tk-opt-day {
        display: inline-block;
        cursor: pointer;
        &.active {
          font-family: $tk-font-bold;
          cursor: default;
        }
      }
      .tk-opt-custom {
        padding: 0 5px 0 0;
      }
      .tk-opt-week {
        padding: 0px 5px;
        text-align: right;
      }
      .tk-opt-day {
        padding: 0px 0px 0px 5px;
        text-align: left;
        width: 28px;
      }
    }
  }

  // TODO: probably should remove this once a better "loading" solution is found.
  .loading {
    height: 50px;
    border-bottom: 1px solid #E5E5E5;
    text-align: center;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;

    h3 {
      padding-top: 15px;
    }
  }

  .tk-special-top-item {
    .tk-icon-plus {
      color: #b2b2b2;
    }
  }
}

/*
Vertically centered client/project name or just project name
Example:
<div class="tk-row-title">
  <div class="tk-title-center-wrap">
    <div class="tk-project-client" ng-if="clientName">{{clientName}}</div>
    <div class="tk-project-name">{{projectName}}</div>
  </div>
</div>
*/
.tk-row-title {
  position: relative;
  height: 50px;
}

.tk-expense-row-title {
  width: 82%;
  float: left;
}

.tk-title-center-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  font-family: $tk-font-bold;
  font-size: 14px;
}

.tk-project-client, .tk-project-name {
  font-size: 14px;
  line-height: 14px;
}

.tk-project-client {
  font-family: $tk-font-normal;
  margin-bottom: 2px;
}
// TODO name more generically, being used for projects and leavetypes
.tk-project-name {
  font-family: $tk-font-bold;
}

/* `TimeTracker
--------------------------------------------------------------------------------*/

.tk-time-tracker-controls {
  button {
    background: none;
    padding: 0px;
    margin: 0px;
    display: inline-block;
    font-size: 12px;
    line-height: initial;
    height: 16px;
  }
}

// should be extracted into more common component!
.tk-time-tracker-controls, .tk-expense-tracker-controls {
  padding: 10px 0px;
  font-size: 12px; // should be defined somewhere higher up!
  float: left;

  .control {
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
    &.processing {
      opacity: 0.5;
      cursor: default;
    }
  }

  .tk-icon-expand, .tk-icon-collapse, .tk-icon-clear, .tk-icon-restore, .tk-icon-trash, .tk-icon-largeplus {
    color: $greyIcon;
    float: left;
    padding-right: 8px; // current default spacing between icon and text, overridden below on per icon basis
    font-size: 16px;
  }
  .tk-icon-expand, .tk-icon-collapse, .tk-icon-largeplus {
    margin-top: -1px;
  }
  .tk-icon-clear, .tk-icon-restore {
    margin-top: -2px;
    padding-right: 7px;
  }
  .tk-icon-largeplus {
    padding-right: 5px;
  }
}

.tk-time-tracker-col-right-button {
  min-height: $timeTrackerRowHeight;
  width: 180px;
  float: right;
  font-size: 12px;
  button {
    margin: 0px;
    width: 100%;
  }
  .tk-status-white {
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #666;
    border-radius: 2px;
    border: 1px solid #e0e0e0;
    div { // cheap and hacky, fix this..
      float: left;
    }
    img {
      margin-top: 1px;
    }
    .tk-icon-check-small {
      font-size: 16px;
      padding: 6px 0px 6px 26px;
    }
  }
}

.tk-time-tracker {
  position: relative;
  .focus-el {
    &:focus {
      outline: none;
    }
    width: 0px;
    height: 0px;
    display: inline;
  }

  .tk-time-tracker-header .tk-time-tracker-cel {
    font-size: 12px;
  }

  .tk-no-category {
    color: #999;
  }

  .tk-time-tracker-header,
  .tk-time-tracker-footer,
  .tk-time-tracker-row {
    background: #fff;
    min-height: $timeTrackerRowContainerHeight;
    border-bottom: 1px solid #E5E5E5;
    padding: $timeTrackerRowPadding 0px $timeTrackerRowPadding;
    @include tk-clearfix();

    .tk-time-tracker-cel {
      min-height: $timeTrackerRowHeight;
      margin-right: 14px;
    }

    .tk-time-tracker-col-right {
      min-height: $timeTrackerRowHeight;
      width: 268px;
    }

    .tk-time-tracker-row-summary + .tk-time-tracker-col-right {
      padding-top: 10px;
      width: 180px;
    }

    .push-right {
      padding-left: 25px;
    }

    .tk-approval-detail-col {
      display:table-cell;
      min-height: $timeTrackerRowContainerHeight;
      border-right: 1px dotted #E5E5E5;
      padding: 18px 10px;

      &.col-1 {
        width: 167px;
        padding-left: 0px;
      }

      &.col-2 {
        width: 160px;
      }

      &.col-3 {
        width: 281px;
      }

      &.col-4 {
        width: 90px;
        padding: 0px;
        border-right: none;
        text-align: right;
        font-family: $tk-font-bold;
      }
    }

    .tk-time-tracker-col-right {
      float: right;
      position:relative;

      .tk-time-tracker-legend {
        position: absolute;
        z-index: 98;
        top: 25px;
        left: 0px;
        background: #fff;
        @include tk-box-shadow(0, 1px, 4px, 0, rgba(0,0,0,0.4));
        padding: 10px;
        width: 250px;
      }
      .tk-time-tracker-legend-row {
        height: 18px;
        line-height: 18px;
      }
      .tk-time-tracker-legend-icon {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-right: 5px;
        background: #333;
        &.scheduled { background: #EAEAEA; }
        &.entered { background: #24A2EB; }
        &.confirmed {
          &.more { background: #005B82; }
          &.less { background: #ffffff; border: 1px solid #24A2EB; }
        }
        &.remaining { background: #84D5F7; }
      }
    }

    .tk-time-tracker-cel-container {
      float: left;
      width: 50px;
      margin-right: 14px;
      height: 100%;

      &.last-approval-column {
        margin: 0px;
      }
    }


    /* `Unorganized
    ----------------------------------------*/


    .tk-time-tracker-cel {
      float: left;
      width: 50px;
      text-align: center;
      border-radius: 3px;

      .tk-icon-lock, .tk-icon-check-small {
        float: right;
        position: relative;
        width: 0px;
        font-size: 16px;
        opacity: 0.45;
        color: #fff;
        right: 14px;
      }

      .tk-icon-lock {
        top: 0px;
      }

      .tk-icon-check-small {
        top: -3px;
      }

      &.empty {
        border: 1px solid #e0e0e0;
        color: #474747;
        .tk-icon-lock, .tk-icon-check-small {
          opacity: 0.25;
          color: #000;
        }
      }

      &.read-only {
        &.interactive {
          cursor: pointer;
        }
      }
    }

    .tk-time-tracker-row-title {
      display: table;
      height: 30px; // needed to center single lines, multi-lines wont respect this because table
      width: 180px;
      word-break: break-word;
    }

    .tk-time-tracker-row-title-wrap {
      display: table-cell;
      vertical-align: middle;
    }

    .tk-hours {
      font-family: $tk-font-bold;
      font-size: 14px;
      line-height: $timeTrackerRowHeight - 2;
    }


    input.tk-hours {
      line-height: initial;
      text-align: center;
    }
  }

  // TODO refactor footer out from shared styles on ln:222
  .tk-time-tracker-footer {
    border-bottom: none;
    padding: $timeTrackerRowPadding 0px 0px 0px;
  }

  .tk-approval-detail-row {
    min-height: $timeTrackerRowContainerHeight;
    border-bottom: 1px solid #E5E5E5;
    padding: 0px;
    margin-left: 20px;

    &:last-child {
      margin-left: 0px;
      padding-left: 20px;
    }

    .tk-toggle-approve {
      padding-top: $timeTrackerRowPadding;
    }
  }

  // TKTime tracker row
  // TODO refactor entire row
  // Separate elements into their own blocks (BEM)
  .tk-time-tracker-row {
    &.timing {
      background: #bae5f4;
      box-shadow: inset 0px 1px #fff, inset 0px -1px #fff;
    }
  }

  .tk-time-tracker-row--placeholder {
    .tk-timer-button {
      display: none;
    }
    &:hover {
      .tk-time-tracker-row__notes {
        &::-webkit-input-placeholder {
          opacity: 1;
        }
      }
    }
  }

  .tk-time-tracker-row--present-time {
    &:hover{
      .tk-time-tracker-row__notes {
        width: calc(100% - 86px);
      }
      .tk-timer-button {
        display: inherit;
      }
    }
  }

  // trying out BEM style syntax
  // going to try and refactor as I style
  //
  // .block {}
  // .block__element {}
  // .block--modifier {}

  .tk-time-tracker-header--day-mode {
    font-family: $tk-font-bold;
    font-size: 20px;
    height: 51px;
  }

  .tk-time-tracker-row {
    .empty.read-only {
      border-color: #f5f5f5;
      background: #f5f5f5;
    }
  }

  .tk-time-tracker-row--expanded {
    background: #f5f5f5;
    .empty.read-only {
      border-color: #ececec;
      background: #ececec;
    }
  }

  .tk-time-tracker-row--expanded-approval {
    .tk-time-tracker-row-title {
      width: 150px;
    }
  }

  .tk-time-tracker-row--empty-itemized {
    color: $greyIcon;
    line-height: 30px;
    padding-left: 10px;
  }

  .tk-time-tracker-row__col-left {
    float: left;
    width: 180px;
    min-height: $timeTrackerRowHeight;
    line-height: $timeTrackerRowHeight;
    margin: 0px 20px 0px 10px;
  }

  .tk-time-tracker-row__col-left--day-mode {
    width: 563px;
  }

  // TODO refactor this, seems clunky to define this so many times
  .tk-time-tracker-row__col-left--hoursminutes-day-mode {
    width: 389px;
  }

  // .tk-time-tracker-row__dates {}

  .tk-time-tracker-row__col-center {
    min-height: $timeTrackerRowHeight;
    float: left;
    margin-right: 14px; // TODO refactor out, hacky
  }

  .tk-time-tracker-row__col-center--hoursminutes-day-mode {
    width: 160px;
  }

  .tk-time-tracker-row__col-center--itemized-day-mode {
    display: flex;
    width: 370px;
  }

  .tk-time-tracker-row__notes {
    float: left;
    background: #fff;
    height: $timeTrackerRowHeight;
    width: 100%;
    padding: 5px;
    border: solid 1px rgb(224, 224, 224);
    border-radius: 3px;
    margin-right: 14px;
    &::-webkit-input-placeholder {
      opacity: 0;
    }
    &:focus {
      cursor: text;
      background: #fff;
      &::-webkit-input-placeholder {
        opacity: 1;
      }
    }
  }

  .tk-time-tracker-row--timing {
    .tk-time-tracker-row__notes {
      width: calc(100% - 156px);
    }
  }

  .tk-time-tracker-row--hovering {
    .tk-time-tracker-row__notes {
      width: calc(100% - 115px);
      &::-webkit-input-placeholder {
        opacity: 1;
      }
    }
  }

  .tk-time-tracker-row__control {
    color: $greyIcon;
    width: 30px;
    height: $timeTrackerRowHeight; // 30px
    float: left;
    padding: 7px;
    font-size: 16px;
    .tk-icon-plus {
      margin: -10px; // Hack to make the
      padding: 10px; // clickable region larger
      opacity: 1;
      &:hover {
        opacity: 0.75;
      }
    }
    .tk-icon-plus-hidden { // use this to keep spacing when we want to hide the button for timesheet locking
      margin: -10px; // Hack to make the
      padding: 10px; // clickable region larger
      opacity: 0;
    }
  }

  .tk-time-tracker-row__delete-popover {
    color: #000;
    padding: 15px;
    width: 215px;
    button {
      margin-left: 0px;
      margin-right: 0px;
      &:last-child { margin-left: 10px; }
    }
  }

  // .tk-time-tracker-row__summary {}

  .tk-time-tracker-row__link {
    color: $greyIcon;
    float: left;
    font-size: 16px;
    line-height: 30px;
    opacity: 0;
    cursor: pointer;
    margin-left: -46px;
    padding: 0px 10px;
  }

  .tk-time-tracker-row__link--hover {
    opacity: 1;
  }

/* `Unorganized
--------------------------------------------------------------------------------*/

  .tk-icon-check-rounded, .tk-icon-warning {
    font-size: 14px;
    padding-top: 4px;
  }

  .tk-hours-summary {
    color: #666666;
    margin-top: 7px;
    &.tk-confirmed {
      color: #000;
    }
    &.tk-confirmable {
      cursor: pointer;
      margin-top: 2px;
      padding: 5px 0px 5px 0px;
      border: 1px solid transparent;
      .confirm-text {
        padding-top: 4px;
        font-size: 12px;
      }
      &:hover {
        color: #FFFFFF;
        background-color: #3576BA;
      }
      &:active {
        background-color: #4C649D;
      }
      &:focus {
      background-color: #3576BA;
      outline: #6097D2;
    }
    }
    &.tk-insufficient-hours, .tk-icon-warning, .tk-past-confirmable {
      color: #ee263D;
    }
  }

  .tk-cell-popup-container {
    width: 50px;
    height: $timeTrackerRowHeight;
    &.half-day-mode {
      .tk-menu-header {
        width: 165px;
      }
      .tk-menu {
        width: 160px;
      }
    }
  }

  .tk-cell-popup-container {
    button {
      width: 135px;
      float: right;
    }
    .tk-popover {
      padding: 10px;
    }
  }

  .tk-exceedsMaximum-popup-container {
    width: 260px;
    button {
      color: #fff;
    }
  }

  .tk-time-tracker-row-summary {
    display: inline-block;
    width: 83px; // 78px;
    text-align: right;
    font-family: $tk-font-normal;
    white-space: nowrap;
    .tk-confirmed {
      color: #666666;
    }

    height: $timeTrackerRowHeight;
    line-height: $timeTrackerRowHeight;

  }

  &.approval-timesheet {
    .tk-time-tracker-header {
      padding: 0px 0px 10px 0px;
      .tk-time-tracker-row__col-left {
        width: 210px;
        margin-right: 0px;
      }
    }
    .tk-time-tracker-header > .tk-time-tracker-cel {
      margin-top: 10px;
    }
    .tk-time-tracker-row__link {
      margin-left: -37px;
      padding: 0px 5px 0px 15px;
    }
    .tk-time-tracker-row__col-left {
      margin-left: 0px;
      margin-right: 30px;
    }
    .tk-time-tracker-row-summary {
      margin-left: -2px;
    }
  }
}

.tk-toggle-approve {
  float: left;
  min-height: $timeTrackerRowHeight;
  line-height: $timeTrackerRowHeight;
  padding-right: 10px;
}

.has-gradient {
  opacity: 0.5;
  text-shadow: none;
  color: #111111;

  &.confirmed {
    opacity: 1;
  }
}

// TODO extract into own file
/* `Project Picker popover
--------------------------------------------------------------------------------*/

$lightGray: #E5E5E5;
$highlight: #EFEFEF;
$grayLine: #e5e5e5;

tk-time-tracker-project-picker {
  outline: none;
}

.tk-project-picker {
  height: 100%;
  display: inline-block;
  margin: 0;
}

// mult-pane
.tk-project-picker, .tk-time-tracker-row__control {
  $paneWidth: 218px;
  $paneHeight: 400px;

  .tk-multi-pane {
    color: #000;
    border-radius: 3px;
    overflow: hidden;
    width: $paneWidth;
    height: $paneHeight;

    .panes {
      width: $paneWidth;
      height: $paneHeight - 50px; // subtract title bar height
    }

    .section-title {
      background-color: $lightGray;
      border-top: 1px solid #d7d7d7;
      border-bottom: 1px solid #d7d7d7;

      h4 {
        @include border-box;
        height: 45px;
        width: 100%;
        padding-left: 10px;
        display: table-cell;
        vertical-align: middle;
        font-size: 14px;
      }

      & + .pane-link {
        border-top: 0;
      }
    }

    ul {
      margin: 0;
      padding: 0;
    }

    // IE doesn't like overflow-y:scroll on `.pane`
    .panes {
      overflow: hidden;
      overflow-y: auto;
    }

    .pane {
      overflow-x: hidden;
      width: 100%;
      margin: 0;
      padding: 0;
      min-height: $paneHeight - 50px;
      border-right: 1px solid $grayLine;

      .search-container {
        padding: 7px 9px 7px 9px;
        background-color: #fff;

        .search {
          @include border-box;
          width: 100%;
          height: 30px;
          border-radius: 3px;
        }
      }
    }
  }

  .tk-multi-pane {
    .pane-link {
      @include border-box;
      width: 100%;
      min-height: 45px;
      padding: 4px 10px 4px 10px;
      border-top: 1px solid $grayLine;
      position: relative;

      &:last-child {
        border-bottom: 1px solid $grayLine;
      }

      &.highlighted {
        background-color: $highlight;
      }

      &.project-has-phases {
        .details {
          .client {
            max-width: 140px;
          }
          .project {
            max-width: 140px;
          }
        }
      }
      &.combined-recent {
        .details {
          padding-left: 0;
          width: 100%;
          .project {
            &::before {
              content: "+ "
            }
            font-size: 12px;
            font-weight: 700;
            width: 100%;
          }
        }
      }

      .icon {
        vertical-align: middle;
        height: 37px;
        display: table-cell;
        padding-left: 4px;
        padding-top: 6px;
      }

      .details {
        padding-top: 4px;
        padding-left: 9px;
        height: 45px - 8px; // pane link height minus padding
        max-width: $paneWidth - (10px + 10px); // width minus pane link padding
        display: table-cell;
        vertical-align: middle;
        word-wrap: break-word;

        .client {
          font-size: 12px;
          width: 160px;
        }

        .project {
          font-size: 14px;
          width: 160px;
        }
      }

      .tk-icon-arrow-right {
        padding: 14px 5px 5px 5px;
        display: table-cell;
      }
    }
  }

  // pane: select project
  .pane--select-project {}

  // pane: select phase
  .pane--select-phase {}

  // pane: select category
  .pane--select-category {}
}

.tk-timesheet-section-heading {
  padding: 0px 0px 20px;
  color: $greyIcon;
}

.tk-show-more {
  display: inline-block;
  margin: -20px 0 50px -20px;
  padding: 20px;
}
