// @import "mixins.css.scss";

[class^="tk-sprite-"],
[class*=" tk-sprite-"] {
  background-image: image-url('spritesheet.png');
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}

[class^="tk-icon-"],
[class*=" tk-icon-"] {
  display: inline-block;
  font-family: '10kft';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tk-sprite-logo-small { background-position: -155px -56px; width: 40px; height: 40px; } // look into stacking icons

.tk-icon-plus:before { content: "\e61b"; }
.tk-icon-x:before { content: "\e61a"; }
.tk-icon-share:before { content: "\e61c"; }
.tk-icon-edit:before { content: "\e618"; }
.tk-icon-person:before { content: "\e607"; }
.tk-icon-calendar:before { content: "\e616"; }
.tk-icon-tile-control-small:before { content: "\e611"; }
.tk-icon-tile-control-truncated:before { content: "\e610"; }
.tk-icon-tile-control-full:before { content: "\e60f"; }
.tk-icon-bold:before { content: "\e60e"; }
.tk-icon-italic:before { content: "\e60d"; }
.tk-icon-font-size:before { content: "\e60c"; }
.tk-icon-eye:before { content: "\e612"; }
.tk-icon-hide:before { content: "\e601"; }
.tk-icon-list:before { content: "\e614"; }
.tk-icon-grid:before { content: "\e615"; }
.tk-icon-column:before { content: "\e613"; }
.tk-icon-group { background-position: -105px -55px; width: 40px; height: 40px; } // TODO create icon, add to sprite image

.tk-icon-check-rounded:before { content: "\e600"; }
.tk-icon-arrow-right:before { content: "\e602"; }
.tk-icon-arrow-left:before { content: "\e603"; }
.tk-icon-approve:before { content: "\e604"; }
.tk-icon-plus-square:before { content: "\e605"; }
.tk-icon-warning:before { content: "\e606"; }
.tk-icon-expand:before { content: "\e608"; }
.tk-icon-collapse:before { content: "\e609"; }
.tk-icon-list-numbers:before { content: "\e60a"; }
.tk-icon-list-bullets:before { content: "\e60b"; }
.tk-icon-edit-layout:before { content: "\e617"; }
.tk-icon-trash:before { content: "\e619"; }
.tk-icon-timer:before { content: "\e61f"; }
.tk-icon-lock:before { content: "\e61e"; }
.tk-icon-largeplus:before { content: "\e61d"; }
.tk-icon-restore:before { content: "\e620"; }
.tk-icon-clear:before { content: "\e621"; }
.tk-icon-arrowdown:before { content: "\e622"; }
.tk-icon-external-link:before { content: "\e623"; }
.tk-icon-arrow-box:before { content: "\e624"; }
.tk-icon-check-small:before { content: "\e625"; }

// thanks fontawesome!
.tk-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.tk-progress-spinner {
  background-image: image-url('sprite-progress.png');
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  animation: spinner 3s linear infinite;
  -moz-animation: spinner 3s linear infinite;
  -webkit-animation: spinner 3s linear infinite;
}

/* `Gradients
--------------------------------------------------------------------------------*/

.grad-primary {
  @include tk-gradient-background(rgba(53, 118, 186, 1), rgba(53, 118, 186, 1));
}

.grad-blue {
  @include tk-gradient-background(#75CDFE,#75CDFE);
}

.grad-blue-inverted {
  @include tk-gradient-background(rgba(117, 205, 254, 1), rgba(117, 205, 254, 1));
}

.grad-orange {
  @include tk-gradient-background(rgba(255, 167, 59, 1), rgba(255, 167, 59, 1));
}

.grad-purple {
  @include tk-gradient-background(rgba(205, 137, 240, 1), rgba(205, 137, 240, 1));
}

.grad-gray {
  @include tk-gradient-background(#C0C0C0,#C0C0C0);
}

.grad-dark-gray {
  @include tk-gradient-background(#C0C0C0, #C0C0C0);
}

.grad-black {
  @include tk-gradient-background(rgb(88, 88, 88), rgb(65, 65, 65));
}

.grad-red {
  @include tk-gradient-background(#ed004e, #c70033);
}

/* `Square icon
--------------------------------------------------------------------------------*/

.square-icon {
  // defaults
  width: 14px;
  height: 14px;
  border-radius: 3px;
  display: inline-block;

  &.small {
    width: 14px;
    height: 14px;
    border-radius: 3px;
  }

  // purple
  &.type-internal {
    @extend .grad-purple;
  }

  // blue
  &.type-confirmed {
    @extend .grad-blue;
  }

  // orange
  &.type-leave {
    @extend .grad-orange;
  }

  &.type-tentative {
    @extend .grad-gray;
  }
}
