// @import "variables.css.scss";

input[type=text], textarea {
  border: 1px solid $tk-border-color-input;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  float: none;
}

textarea {
  padding-top: 5px;
  padding-bottom: 5px;
}

input[type=text]:focus, textarea:focus {
  box-shadow: 0 0 5px rgba(119, 214, 173, 1);
  border: 1px solid rgba(255, 195, 115, 1);
}

.tk-input-label {
  margin: 10px 0px 5px 0px;
  font-weight: normal;
  display: inline-block;
}

.tk-input {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #000000;
  background-color: #f5f5f5;
  height: 30px;
  width: 100%;
  text-align: left;
  border: 1px solid #e5e5e5;
  padding-left:7px;
}

.tk-form-row {
  margin-bottom: 15px;
}

// custom radio button, will work for checkboxes too
// http://www.hongkiat.com/blog/css3-checkbox-radio/
.tk-radio {
  label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    font-size: 13px;
    &:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      position: absolute;
      left: 0;
      bottom: 1px;
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 1);
      border-radius: 8px;
    }
  }
  input[type=radio] {
    display: none;
  }
  input[type=radio]:checked + label:before {
    content: "\2022";
    font-size: 30px;
    text-align: center;
    line-height: 18px;
  }
}
