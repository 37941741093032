// variables

// colors
@use "sass:math";

$tk-default-text-color: black;

$tk-background-color: #f2f2f2;
$tk-background-color-toolbar: $tk-background-color;
$tk-background-color-hover: #e1e1e1;
$tk-background-color-light: rgb(242, 242, 242);
$tk-background-color-medium: #3d3d3d;
$tk-background-color-dark: rgb(35, 35, 35);
$tk-background-color-blue: rgb(0, 48, 89);

$tk-border-color-light: rgb(204, 204, 204);
$tk-border-color-dark: rgb(33, 33, 33);
$tk-border-color-input: rgb(229, 229, 229);
$tk-border-light: 1px solid $tk-border-color-light;
$tk-border-dark: 1px solid $tk-border-color-dark;

$tk-primary-color: black;
$tk-brand-color: red;
$tk-label-color: gray;
$tk-header-text-color: #808080;

$tk-white: #fff;
$tk-off-white: #f2f2f2;
$tk-green: #00BF64;
$tk-light-blue: #0073EC;
$tk-contrast-light-blue: #0970BE;

// toolbars
$tk-toolbar-height-px: 50px;

// panels
$tk-panel-1-width-px: 260px;
$tk-panels-2-3-half-width-calc: calc(50% - #{$tk-panel-1-width-px * 0.5});
$tk-panels-2-3-full-width-calc: calc(100% - #{$tk-panel-1-width-px});
$tk-panel-3-half-width-left-calc: calc(50% + #{$tk-panel-1-width-px * 0.5});

// typography
$tk-font-normal: 'GibsonRegular', Verdana, sans-serif;
$tk-font-light: 'GibsonLight', Verdana, sans-serif;
$tk-font-bold: 'GibsonSemiBold', Verdana, sans-serif;

// grid
$tk-grid-width-percent: 95%;
$tk-grid-max-width-px: 880px;
$tk-grid-margin-px: 10px;
$tk-grid-column-width-px: math.div($tk-grid-max-width-px - 40px, 3);
$tk-grid-row-height-px: $tk-grid-column-width-px;
$tk-tile-border-width-px: 3px;

$tk-grid-breakpoint-1-px: 1670px;
$tk-grid-breakpoint-2-px: $tk-grid-max-width-px + $tk-panel-1-width-px;
$tk-grid-breakpoint-3-px: $tk-grid-max-width-px + $tk-panel-1-width-px - $tk-grid-column-width-px;

// sortview
$tk-sortview-title-height-px: 70px;
$tk-sortview-toolbox-width-px: 240px;
$tk-sortview-group-item-height-px: 58px;

// misc
$tk-arrow-height-px: 20px;
$tk-arrow-width-px: 40px;
$tk-popover-margin-px: $tk-arrow-height-px - 6px;
$tk-popover-background-color: white;

$tk-attributes-table-row-height-px: 34px;

$tk-default-tranisition-duration-ms: 400ms;

// approval page panels
$tk-approvals-panel-1-width: 220px;
$tk-approvals-panel-1-width-calc: calc(100% - #{$tk-approvals-panel-1-width});

// icons
// components
// tables
// buttons
// forms
// media queries

// radio button
$tk-radio-button-size: 22px;

// billing
$tk-billing-container-width: 775px;
$tk-billing-section-margin-top: 55px;
$tk-sku-box-height: 350px;//375px;
$tk-sku-box-margin: 8px;
$tk-billing-titles-font-family: $tk-font-light;
$tk-billing-titles-font-size: 30px;
$tk-billing-payment-method-height: 75px;
$tk-billing-payment-method-label-font-size: 18px;
$tk-billing-payment-method-price-font-size: 30px;


// work status options
$work_status_blue_bright: #92D4F4;
$work_status_gray: #E0E0E0;
$work_status_red: #E81A56;
$work_status_yellow: #FAC905;
$work_status_green_bright: #00bf64;
$work_status_blue_dark: #24A2EB;
$work_status_purple: #C285FF;
$work_status_orange: #FF8533;
$work_status_green_dark: #007C3F;
