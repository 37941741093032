.tk-date-picker-container {
  @include tk-clearfix;
  border-radius: 3px;
  border: 1px solid #fff;
  background: #f2f2f2;
  button {
    margin: 0px 10px 10px 0;
    float: right;
  }
  &.tk-date-range-picker-container {
    width: 466px;
  }
}
.tk-date-picker-range-block {
  display: inline-block;
}
.tk-date-picker-header {
  margin-top: 10px;
  color: #999;
  text-align: center;
}
.tk-date-picker {
  display: inline-block;
  float: left;
  padding: #{10px - 3px} 10px 10px 10px;
  background: #FFFFFF;

  .tk-date-picker-label {
    font-size: 14px;
    color: #666666;
    margin: 5px 0px;
  }

  [date-picker] {
    padding: 0px;
  }

  [date-picker] table {
    border: none;
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 14px;
    background-color: #f2f2f2; // rgb(242, 242, 242);
    border-collapse: collapse;
  }

  [date-picker] span {
    background-color: #FFFFFF;
    margin: 0px;
    -webkit-border-radius: 0px;
       -moz-border-radius: 0px;
            border-radius: 0px;
  }

  [date-picker] [ng-switch-when="year"] span,
  [date-picker] [ng-switch-when="month"] span,
  [date-picker] [ng-switch-when="minutes"] span {
    width:25%;
    background-image: -moz-linear-gradient(top, #fafafa, #ededed);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fafafa), to(#ededed));
    background-image: -webkit-linear-gradient(top, #fafafa, #ededed);
    background-image: -o-linear-gradient(top, #fafafa, #ededed);
    background-image: linear-gradient(to bottom, #fafafa, #ededed);
  }

  [date-picker] th {
    background-color: #FFFFFF;
    font-weight: normal;
    width: 30px;
    min-width: 30px;
    height: 27px;
    padding: 0px 0px;
    text-align: center;
    -webkit-border-radius: 0px;
       -moz-border-radius: 0px;
            border-radius: 0px;
  }

  [date-picker] td {
    width: 30px;
    min-width: 30px;
    height: 27px;
    padding: 0px 0px;
    background-image: -moz-linear-gradient(top, #fafafa, #ededed);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fafafa), to(#ededed));
    background-image: -webkit-linear-gradient(top, #fafafa, #ededed);
    background-image: -o-linear-gradient(top, #fafafa, #ededed);
    background-image: linear-gradient(to bottom, #fafafa, #ededed);
    border: 1px solid #e6e6e6;
    -webkit-border-radius: 0px;
       -moz-border-radius: 0px;
            border-radius: 0px;
  }

  [date-picker] .now {
    background-color: #FFFFFF;
    background-image: none;
    text-shadow: none;
    color: #1A1A1A; 
  }

  [date-picker] .active.now{
    font-family: GibsonSemiBold;
  }

  [date-picker] [ng-switch-when="year"] span.active,
  [date-picker] [ng-switch-when="month"] span.active,
  [date-picker] [ng-switch-when="minutes"] span.active,
  [date-picker] .active {
    color: #1A1A1A;
    background-color: #B3CCEA;
    *background-color: #B3CCEA;
    background-image: -moz-linear-gradient(top, #B3CCEA, #B3CCEA);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#B3CCEA), to(#B3CCEA));
    background-image: -webkit-linear-gradient(top, #B3CCEA, #B3CCEA);
    background-image: -o-linear-gradient(top, #B3CCEA, #B3CCEA);
    background-image: linear-gradient(to bottom, #B3CCEA, #B3CCEA);
    background-repeat: repeat-x;
    // border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#B3CCEA', endColorstr='#B3CCEA', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  }

  [date-picker] .active:hover,
  [date-picker] .now:hover {
    color: #000;
    text-shadow: none;
  }

  [date-picker] thead tr:last-child {
    font-size: 12px;
  }

  [date-picker] thead tr:first-child {
    font-family: GibsonSemiBold;
    font-size: 14px;
  }
}

button:focus {
  outline: 5px auto -webkit-focus-ring-color;
}

[date-picker] th:hover,
[date-picker] [ng-switch-when="date"] td span:hover {
  cursor: pointer;
  background: #EEEEEE;
}

[date-picker] th:active,
[date-picker] [ng-switch-when="date"] td span:active {
  cursor: pointer;
  background: #E0E0E0;
}

#setToToday {
  color: #0970BE;
}

[date-picker] span.disabled {
  background-color: #FFFFFF;
  color: #979797;
}