/*
 * This is a manifest file that'll be compiled into proposals-widgets.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, vendor/assets/stylesheets,
 * or vendor/assets/stylesheets of plugins, if any, can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the top of the
 * compiled file, but it's generally better to create a new file per style scope.
 *
 *= require_self
 *= require angular-datepicker/index
 */

// Is this necessary? It might break host page content

@import "font-gibson.css.scss";
@import "font-10kft.css.scss";

// Core variables and mixins
@import "variables.css.scss";
@import "mixins.css.scss";

@import "keyframes.css.scss";

$body-tag: "&.widget-wrapper";

.widget-wrapper {
  @import "../../../vendor/assets/stylesheets/normalize.css.scss";
  @import "sprites.css.scss";

  // Core CSS
  @import "scaffolding.css.scss";
  @import "text.css.scss";
  @import "forms.css.scss";
  @import "buttons.css.scss";

  // Components
  @import "components.css.scss"; // should be broken up into popover, panels, tiles, etc.
  @import "approvables-tracker.css.scss";
  @import "timer.css.scss";
  @import "menu.css.scss";
  @import "weekview-notes.css.scss";
  @import "filter-sidebar.css.scss";
  @import "date-picker.css.scss";
  @import "expenses.css.scss";
  @import "graphs.css.scss";
  @import "approvals-filter-list.css.scss";
  @import "approvablesheet.css.scss";
  @import "user-badge.css.scss";

  // View specific
  @import "multi-pane.css.scss";
  @import "approvals-page.css.scss";
}