// @import "./mixins.css.scss";

h1, h2, h3, h4, h5, p {
  font-family: inherit;
  font-weight: 500;
  margin: 0;
}

h1 {
  font-size: 36px;
}

.tk-text-h1 {
  font-size: 36pt;
  font-weight: bold;
  line-height: 1.1;
}

.tk-text-h2 {
  font-size: 24pt;
  font-weight: bold;
  line-height: 1.1;
}

.tk-text-h3 {
  font-size: 18pt;
  font-weight: bold;
  line-height: 1.1;
}

.tk-left {
  text-align: left;
}

.tk-debug {
  @include tk-debug;
}

.tk-label-medium {
  font-size: 12pt;
}

.tk-label-medium-strong {
  font-size: 12pt;
  font-weight: bold;
}

.tk-label-small {
  font-size: 12pt;
}

.tk-label-small-strong {
  font-size: 10pt;
  font-weight: bold;
}

.tk-menu-label {
  display: inline-block;
}
