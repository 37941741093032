// @import "variables.css.scss";

.tk-filter-sidebar {
  float: left;
  width: $tk-approvals-panel-1-width;
  left: 0px;
  top: 0px;
  height: 100%;

  h3 {
    color: #8C8C8C;
    font-size: 14px;
  }
}

ul.filter-list {
  margin-top: 5px;
  margin-bottom: 30px;
  > li {
    color: #000000;
    line-height: 30px;
    border-radius: 2px;
    padding: 0px 5px 0px 5px;
    &:hover, &.selected {
      background-color: #bfbfbf;
    }
    margin-left: -5px;
    margin-right: 20px;
    .tk-full-width {
      width: 100%;
    }
    .tk-filter-content {
      cursor: pointer;
    }
  }
  .tk-filter-selection {
    font-family: $tk-font-bold;
  }
}

.tk-filter-content {
  .tk-popover-content {
    width: auto;
    min-width: 200px;
    li.search {
      input {
        width: 100%;
        height: 30px; // potentially extract into more common place
      }
      border-bottom: 1px solid #efefef;
      padding: 0px 10px 5px;
      margin: 0px;
    }
    .tk-filter-popup-container {
      min-height: 64px;
      &.fixed-height {
        max-height: 420px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .tk-date-range-picker-container {
      [ng-transclude] {
        border-bottom: 1px solid #ccc;
      }
      .tk-date-picker-header {
        margin: 0 0 -14px 0;
      }
      ul {
        padding-left: 4px;
        max-width: 100%;
        li {
          margin: 0;
          display: inline-block;
          &.interactive:hover {
            background-color: #bfbfbf;
          }
        }
      }
    }
    ul {
      padding: 3px 0px;
      margin: 0px;
      cursor: default;
      max-width: 300px;
      li {
        margin: 0px 5px;
        line-height: 30px;
        padding: 0px 10px;
        font-size: 14px;
        clear: both;
        &.interactive:hover {
          cursor: pointer;
          background-color: #efefef;
          border-radius: 2px;
        }
      }
    }
  }
}

.tk-menu-checkbox-left {
  float: left;
  width: 30px;
  height: 30px;
  padding-left: 8px;
  margin-right: 2px;
  &.interactive {
    cursor: pointer;
    &:hover {
      background-color: #eeeeee;
    }
  }
}

.tk-menu-buttons {
  margin-left: 35px;
  height: 30px;
}

.tk-menu-button {
  display: inline-block;
  color: #bfbfbf;
  &.interactive {
    color: #000;
    padding: 0px 8px;
    margin: 4px 0px;
    cursor: pointer;
    border-radius: 2px;
    &:hover {
      background-color: #eeeeee;
    }
  }
}

.tk-menu-checkbox-divider {
  float: left;
  width: 1px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 32px;
  border-right: 1px solid #efefef;
}

.tk-menu-checkbox-right {
  cursor: pointer;
  margin-left: 35px;
  padding: 0px 10px;
  &:hover {
    background-color: #eeeeee;
  }
}

ul li.tk-menu-checkbox {
  // font-size: 12px;
  position: relative;
  padding: 0px !important;
}
