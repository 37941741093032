/*
 * Component specific styles and bootstrap overrides here
 */
// @import "mixins.css.scss";
// @import "variables.css.scss";
 

ul {
  padding: 0;
  list-style: none;
}

hr {
  margin: 2px 0;
  border: none;
  height: 1px;
  background-color: #e5e5e5;
}

// ----------------------------------------------------------------------------
// root div
// ----------------------------------------------------------------------------

.tk-root {
  height: 100vh;
}

// ----------------------------------------------------------------------------
// header
// ----------------------------------------------------------------------------

.tk-header-container {
  position: absolute;
  top: 0px;
  width: 100%;
}

.tk-header {
  @include tk-flex;
  @include tk-flex-justify-space-between;
  height: 50px;
  background-color: $tk-background-color-blue;
  font-size: 14px;
  font-family: $tk-font-normal;
  .tk-header-left, .tk-header-right {
    @include tk-flex;
  }

  .tk-logo {
    margin: 13px -3px 0 21px;
    width: 23px;
    height: 23px;
    background-image: url('/images/resource-management-icon-light.svg');
    background-size: 23px 23px;
    bottom: 6px;
  }

  .tk-link {
    @include tk-clickable;
    color: $tk-header-text-color;
    text-shadow: 0px -1px 0px #000000;
    margin: 0px 10px;
    line-height: 50px; // trick to vertically center
  }

  .tk-selected {
    color: #fff;
    font-family: $tk-font-bold;
  }

  .tk-link-right {
    @include tk-clickable;
    @include tk-align-self-right;
    color: $tk-header-text-color;
  }

  .tk-input {
    background: #5a5a5a;
    border: none;
    &:focus {
      background: #fff;
      border: none;
    }
  }

  .group-popover {
    color: #000;
    text-shadow: none;
    ul { margin: 0px; padding: 10px; }
    li {
      line-height: normal;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

// ----------------------------------------------------------------------------
// main body of content
// ----------------------------------------------------------------------------

.tk-main-container {
  width: 100%;
  position: absolute;
  top: 50px;
  bottom: 0px;
}

.tk-main {
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  overflow: hidden;
}

// ----------------------------------------------------------------------------
// vertical panels
// ----------------------------------------------------------------------------
.tk-panel {
  position: absolute;
  top: 0px;
  bottom: 0px;
  @include tk-transition-all;
}

.tk-panel-1 {
  width: $tk-panel-1-width-px;
  left: 0px;
  border-right: 1px solid $tk-border-color-light;
  .tk-toolbar {
    border-bottom: 1px solid $tk-border-color-light;
  }
}

.tk-panel-2 {
  width: $tk-panels-2-3-half-width-calc;
  left: $tk-panel-1-width-px;

  &.tk-extend-right {
    width: $tk-panels-2-3-full-width-calc;
    display: block;
  }
}

.tk-panel-3 {
  width: $tk-panels-2-3-half-width-calc;
  left: 100%;

  &.tk-extend-left {
    left: $tk-panel-3-half-width-left-calc;
  }
}

@media (max-width: $tk-grid-breakpoint-1-px - 1px) {
  .tk-panel-2 {
    width: $tk-panels-2-3-full-width-calc;
    left: $tk-panel-1-width-px;
  }
  .tk-panel-3 {
    &.tk-extend-left {
      width: $tk-panels-2-3-full-width-calc;
      left: $tk-panel-1-width-px;
      background-color: $tk-background-color;
    }
  }
}

// ----------------------------------------------------------------------------
// toolbar
// ----------------------------------------------------------------------------

.tk-toolbar {
  position: absolute;
  width: 100%;
  height: $tk-toolbar-height-px;
  background-color: $tk-background-color-toolbar;
  text-align: right;
  border-bottom: 1px solid $tk-border-color-light;

  > div {
    display: inline-block;
  }

  button[type=button] {
    border: none;
    border-radius: 2px;
    height: $tk-toolbar-height-px - 10;
    padding: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 10px;
    background-color: $tk-background-color-toolbar;
    background-image: none;
  }

  button[type=button].tk-button-icon-only {
    padding-right: 0px;
  }

  button[type=button].tk-button-no-icon {
    padding-left: 10px;
  }

  button:hover {
    background-color: $tk-background-color-hover;
  }

  button:focus {
    outline: 0;
  }

}

input.tk-toolbar-add-project {
  width: 180px;
  margin-top: 10px;
  margin-left: 10px;
  float: left;
  background-color: $tk-background-color-toolbar;
  border: none;
}

// ----------------------------------------------------------------------------
// tiles (not to be confused with grid tiles)
// ----------------------------------------------------------------------------

.tk-project-tile {
  float: none;
  padding: 16px;
  border-bottom: 1px solid $tk-border-color-light;
  width: $tk-panel-1-width-px;
  overflow: hidden;
  background-size:cover;
  cursor: pointer;
  background-color: white;
}

.tk-project-tile:hover {
  background-color: #f9f9f9;
}

.tk-user-tile {
  float: left;
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #f9f9f9;
  width: 240px;
  height: 100px;
  overflow: hidden;
  background-color: #f9f9f9;
  background-size:cover;
  cursor: pointer;
}

.tk-session-tile {
  float: left;
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #f9f9f9;
  width: 240px;
  height: 100px;
  overflow: hidden;
  background-color: #f9f9f9;
  background-size:cover;
  cursor: pointer;
}

.tk-scrollable-panel-section {
  position: absolute;
  top: $tk-toolbar-height-px;
  bottom: 0px;
  width: 100%;
  overflow-x: hidden;
}

.tk-search-box {
  width: 166px;
  display: inline-block;
}

.tk-arrow {
  width: $tk-arrow-width-px;
  height: $tk-arrow-height-px;
  position: absolute;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    width: $tk-arrow-height-px;
    height: $tk-arrow-height-px;
    background-color: $tk-popover-background-color;
    top: $tk-arrow-height-px * 0.5;
    left: $tk-arrow-height-px * 0.5;
    @include tk-box-shadow;
    @include tk-transform(rotate(45deg));
  }
  &.tk-arrow-grey:after {
    background-color: #f2f2f2;
  }
}

.tk-popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
  background-color: $tk-popover-background-color;
  max-width: none;
  @include tk-box-shadow;
  border: none;
  border-radius: 4px;
  display: inline-block;
}
.tk-popover-content {
  padding: 0;
  font-size: 14px;
}
.tk-popover.bottom > .tk-arrow, .tk-popover.top > .tk-arrow {
  left: 50%;
  margin-left: -$tk-arrow-width-px * 0.5;
}
.tk-popover.right > .tk-arrow, .tk-popover.left > .tk-arrow {
  width: $tk-arrow-height-px; // width-height switch is intentional
  height: $tk-arrow-width-px;
  margin-top: -$tk-arrow-width-px * 0.5;
  top: 50%;
}
.tk-popover.left > .tk-arrow, .tk-popover.top > .tk-arrow {
  @include tk-transform(rotate(180deg));
}
.tk-popover.right {
  margin-left: $tk-popover-margin-px;
  >.tk-arrow {
    left: -$tk-arrow-height-px;
  }
}
.tk-popover.left {
  margin-right: $tk-popover-margin-px;
  >.tk-arrow {
    left: 100%;
  }
}
.tk-popover.bottom {
  margin-top: $tk-popover-margin-px;
  >.tk-arrow {
    top: -$tk-arrow-height-px;
  }
}
.tk-popover.top {
  margin-bottom: $tk-popover-margin-px;
  >.tk-arrow {
    top: 100%;
  }
}

// ----------------------------------------------------------------------------
// scrollable div styles
// ----------------------------------------------------------------------------

.tk-scrollable {
  overflow-x: hidden;
  overflow-y: scroll;
}

// ----------------------------------------------------------------------------
// images
// ----------------------------------------------------------------------------

.tk-img {
  background-image: url('http://placehold.it/350x150'); // This should be overwritten
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vw;
}

.tk-drag-over {
  opacity: 0.5;
}

// ----------------------------------------------------------------------------
// comments
// ----------------------------------------------------------------------------

// Using div to increase specificity
// to override shared styles with .tk-grid-background
div .tk-comments {
  text-align: left;
  font-size: 18px;
  padding: 40px;
  margin-bottom: 60px;
  [class^="tk-icon-"], [class*=" tk-icon-"] {
    margin-right: 3px;
  }
}

.tk-comments-controls {
  height: 45px;
  border-bottom: 1px solid #{$tk-border-color-light};
  font-size: 14px;
  div {
    display: inline-block;
    margin: 5px;
    cursor: pointer;
  }
}

.tk-comment {
  padding: 15px 0;
  .tk-comment-side-panel {
    display: inline-block;
    float: left;
    height: 100%;
    width: 60px;
  }
  .tk-user-profile-pic {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #eee;
    background-size: contain;
  }
  .tk-comment-main-panel {
    display: inline-block;
    width: calc(100% - 60px);
    border-bottom: 1px solid #{$tk-border-color-light};
  }
  .tk-comment-date {
    font-size: 14px;
    color: rgb(85, 85, 85);
    margin: 5px 0 7px 0;
  }
  .tk-comment-value, textarea {
    font-family: $tk-font-light;
    margin: 0 0 15px 0;
  }
  .tk-popover-content {
    font-family: $tk-font-normal;
    padding: 5px;
    button {
      margin: 5px 0 0 0;
    }
  }
  .tk-option {
    cursor: pointer;
    margin: 2px 0 0 0;
  }
  .tk-delete-confirmation {
    min-width: 235px;
  }
  textarea {
    width: 100%;
    min-height: 100px;
    margin: 0;
  }
  button {
    margin: 5px 0 10px 0;
    font-size: 14px;
  }
}

.tk-dotted-line {
  height: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 2px dotted $tk-border-color-light;
}
