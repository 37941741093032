
// For now, this is only used in a few places, but in the future it may make sense to create a tk-menu directive and use these classes to standardize the style/layout

ul.tk-menu {
  li {
    padding: 0px 10px;
    height: 24px;
    line-height: 24px;
    text-align: left;

    &.tk-menu-header {
      color: #666666;
      font-size: 12px;
    }

    &.tk-menu-item {
      cursor: pointer;
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
