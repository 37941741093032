// @import "mixins.css.scss";
// @import "sprites.css.scss";

.tk-timer-button {
  float: right;
  display: table;
  cursor: pointer;
  height: 30px;
  width: 240px;
  min-width: 71px;
  line-height: 30px;
  @extend .grad-primary;
  border-radius: 3px;
}

.tk-timer-button__add {
  display: table-cell;
  color: #FFFFFF;
  padding: 0px 10px;
  border-radius: 3px;
  &:hover {
    @include tk-gradient-background(rgba(53, 118, 186, 1), rgba(53, 118, 186, 1));
  }
}

.tk-timer-button__indicator {
  float: left;
  padding: 0px 6px 0px 0px;
  color:  #FFFFFF;
  .tk-icon-timer {
    position: relative;
    top: 1px;
    height: 13px;
    width: 13px;
  }
}

.tk-timer-button__text {
  float: left;
  color: #FFFFFF;
}

.tk-timer-button__cancel {
  display: none;
  border-radius: 0px 3px 3px 0px;
  @extend .grad-primary;
  &:hover {
    @include tk-gradient-background(rgba(53, 118, 186, 1), rgba(53, 118, 186, 1));
  }
}

.tk-timer-button--locked {
  cursor: default;
}

.tk-timer-button--timing {
  .tk-icon-timer {
    // Code that will run the spin animation on every browser
    // Taken from User: http://stackoverflow.com/users/128165/gaby-aka-g-petrioli
    // at: http://stackoverflow.com/questions/14859322/css3-spin-animation
    -webkit-animation-name: spin;
    -webkit-animation-duration: 3000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 3000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 3000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    @-ms-keyframes spin {
        from { -ms-transform: rotate(0deg); }
        to { -ms-transform: rotate(359deg); }
    }
    @-moz-keyframes spin {
        from { -moz-transform: rotate(0deg); }
        to { -moz-transform: rotate(359deg); }
    }
    @-webkit-keyframes spin {
        from { -webkit-transform: rotate(0deg); }
        to { -webkit-transform: rotate(359deg); }
    }
    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(359deg);
        }
    }
  }
  .tk-timer-button__add {
    border-radius: 3px 0px 0px 3px;
    border-right: 1px solid #177aae;
  }
  .tk-timer-button__cancel {
    display: table-cell;
    border-left: 1px solid #88d1ed;
    padding-left: 6px;
    padding-right: 6px;
    color: #fff;
    vertical-align: middle;
  }
}

.tk-timer-button__locked-popover {
  margin: 0px;
  padding: 10px;
  width: 250px;
}
