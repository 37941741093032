@font-face {
  font-family: '10kft';
  src:url('/fonts/10kft.eot?-e2lfy4');
  src:url('/fonts/10kft.eot?#iefix-e2lfy4') format('embedded-opentype'),
      url('/fonts/10kft.woff?-e2lfy4') format('woff'),
      url('/fonts/10kft.ttf?-e2lfy4') format('truetype'),
      url('/fonts/10kft.svg?-e2lfy4#10kft') format('svg');
  font-weight: normal;
  font-style: normal;
}
