// @import "mixins.css.scss";
// @import "approvables-tracker.css.scss";

.tk-filter-list {
  border-top: 1px solid #E5E5E5;
}

.tk-filter-list-item {
  @include tk-clearfix();
  padding: 15px 0px;
  border-bottom: 1px solid #E5E5E5;
  font-size: 13px;

  &:hover {
    background-color: rgb(239, 239, 239);
  }
}

.tk-filter-list-item__icon {
  float: left;
  padding: 5px 10px 5px 5px;
  font-size: 16px;
  color: #d8d8d8;
}

.tk-filter-list-item__title {
  float: left;
  padding: 5px 0px;
  width: 916px;
}

.tk-filter-list-item__button {
  float: left;
  color: $greyIcon;
  padding: 5px 5px 5px 10px;
  font-size: 16px;

  &:hover {
    opacity: 0.75;
  }
}

.tk-filter-list-item__delete-popover {
  padding: 15px;
  width: 215px;
  button {
    margin-left: 0px;
    margin-right: 0px;
    &:last-child { margin-left: 10px; }
  }
}
