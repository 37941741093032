// @import "mixins.css.scss";

.tk-weekview-notes-container {
  @include tk-transition-all;
  overflow: hidden;
  width: 100%;
}

.tk-weekview-notes {
  @include tk-transition-all;
  float: left;
  width: 436px;
  height: 30px;
  background-color: #F2F2F2;
  margin: 16px 0 0 #{$tk-approvals-panel-1-width - 10px};
  padding: 5px;
  border-radius: 3px;
  input, span {
    width: 100%;
    height: 20px;
    background-color: rgba(0,0,0,0);
    border: none;
    outline: none;
    padding: 0 0 0 7px;
    cursor: pointer;
    &:focus {
      cursor: text;
    }
  }
  span {
    cursor: text;
    display: inline-block;
    margin-top: 2px;
  }
  .tk-arrow.tk-weekview-arrow {
    @include tk-transition-all;
    margin: -24px 0 0 0;
    &:after {
      background-color: #ececec;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}
