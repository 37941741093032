// @import "mixins.css.scss";

.tk-expense-container {
  @include tk-clearfix();
  height: 100%;
}

.tk-expense-tracker-label {
  font-family: $tk-font-bold;
  padding-bottom: 5px;
  font-size: 14px;
}

.tk-expense {
  padding: 0px 10px;
  float: left;
  border-right: 1px dotted #E5E5E5;
  height: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .tk-icon-lock, .tk-icon-check-small {
    float: left;
    margin: 18px 2px 0px -4px;
  }

  &.col1 {
    width: 200px;
    padding: 0px 10px 0px 0px;
  }
  &.col2 {
    width: 295px;
  }
  &.col3 {
    width: 295px;
  }
  &.col4 {
    float: right;
    border-right: none;
    font-family: $tk-font-bold;
  }
}

.tk-expense-container.narrow {
  .col1 {
    width: 120px;
  }
  .col2 {
    width: 250px;
  }
  .col3 {
    width: 274px;
  }
  &.detail {
    .col1 {
      width: 115px;
    }
    .col2 {
      width: 241px;
    }
  }
}

.tk-expense-tracker {
  .tk-icon-plus-square {
    font-size: 16px;
    color: #aaa;
    float: left;
    line-height: 50px;
  }

  .tk-toggle-approve {
    padding-top: 10px;
  }
}

.tk-expense-tracker__header {
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid #E5E5E5;
}

.tk-expense-tracker__footer {
  @include tk-clearfix();
  padding: 10px 0px 10px 0px;
}

.tk-expense-add {
  width: 100%;
  line-height: normal;

  .tk-expense-add-control {
    float: right;
  }

  .header {
    border-radius: 3px 3px 0px 0px;
    overflow: hidden;
    background-color: #000;
    color: #ffffff;
    // margin: 0px 0px 20px 0px;
    padding: 18px;
    @include tk-box-shadow(0, 1px, 3px, 0, rgba(0, 0, 0, 0.2));
  }

  .picker {
    border-top: 1px solid #ededed;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #dadada;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    cursor: pointer;
    height: 30px;
    width: 100%;
    padding: 7px 0px 0px 15px;
    @include tk-gradient-background(#f9f9f9, #e7e7e7);
  }

  .picker-container {
    margin: 6px 0px 10px;
    &.calendar {
      @include tk-popover-background(#f2f2f2);
    }
  }

  .tk-icon-arrowdown {
    font-size: 16px;
    float: right;
    padding: 0px 5px;
  }

  .content {
    border-radius: 0px 0px 3px 3px;

    .tk-button-primary {
      margin-right: 0px;
    }

    &.existing {
      border-radius: 3px;
    }

    height: 195px;
    background-color: #ffffff;
    // margin: 0px 0px 20px 0px;
    padding: 18px;
    @include tk-box-shadow(0, 1px, 3px, 0, rgba(0, 0, 0, 0.2));

    .label {
      color: #666666;
      font-size: 12px;
    }

    input {
      width: 100%;
      height: 30px; // potentially extract into more common place
      margin-top: 6px; // minor alignment adjustment, should be remedied in common styles
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }

    .column-1 {
      width: 50%;
      float: left;
      padding-right: 15px;
      .column-1-1 {
        width: 40%;
        float: left;
        padding-right: 15px;
      }
      .column-1-2 {
        width: 60%;
        float: right;
      }
    }

    .column-2 {
      width: 50%;
      float: right;

      .spacer {
        width: 100%;
        height: 60px;
      }

      .column-2-1 {
        width: 65%;
        padding-right: 15px;
        float: left;
      }
      .column-2-2 {
        width: 35%;
        float: right;
      }
    }
  }
}

.tk-close-x {
  float: right;
  height: 100%;
  margin: -10px;
  padding: 10px;
  color: #fff;
}

.tk-expenses-group-header {
  color: #aaa;
  padding: 20px 0px 0px;
}

.tk-row {
  min-height: 50px;
  line-height: 50px;
  margin: 0px;

  &.divider {
    border-bottom: 1px solid #E5E5E5;
  }

  &.indented {
    margin-left: 10px;
  }

  padding: 0px;
}

// This should be up for removal, ng-click should get pointer cursor all the time
.tk-interactive {
  cursor: pointer;
}

.tk-expense-item {
  &.tk-interactive:hover {
    background-color: rgb(239, 239, 239);
  }

  &.editing {
    margin-left: 0px;
  }
}
