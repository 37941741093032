@font-face {
    font-family: 'GibsonLight';
    src: url('/fonts/gibsonlight-webfont.eot');
    src: url('/fonts/gibsonlight-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/gibsonlight-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'GibsonLight';
    src: url('/fonts/gibsonregular-webfont.eot');
    src: url('/fonts/gibsonregular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/gibsonregular-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'GibsonRegular';
    src: url('/fonts/gibsonregular-webfont.eot');
    src: url('/fonts/gibsonregular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/gibsonregular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'GibsonRegular';
    src: url('/fonts/gibsonsemibold-webfont.eot');
    src: url('/fonts/gibsonsemibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/gibsonsemibold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'GibsonSemiBold';
    src: url('/fonts/gibsonsemibold-webfont.eot');
    src: url('/fonts/gibsonsemibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/gibsonsemibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
